.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #763434;
}

.main-content {
  display: flex;
  gap: 20px;
  width: 100%; /* Ensure full-width on smaller screens */
}

.image-gallery {
  width: 60%;
}

.main-image img {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 8px;
}

.thumbnail-slider {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  overflow-x: auto;
  padding-bottom: 10px;
}

.thumbnail-slider img {
  width: 100px;
  height: auto;
  cursor: pointer;
  border-radius: 4px;
  opacity: 0.7;
  transition: opacity 0.3s, transform 0.2s;
}

.thumbnail-slider img.active,
.thumbnail-slider img:hover {
  opacity: 1;
  border: 2px solid #333;
  transform: scale(1.05);
}

.details-sidebar {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.out-of-stock-button {
  background-color: grey;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: not-allowed;
}

.overview, .additional-info {
  margin-top: 20px;
}

.rent-button, .proceed-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  width: 100%; /* Full-width on mobile for better accessibility */
}

.price-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
}

.price-list label {
  cursor: pointer;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .container {
      padding: 10px;
  }

  .main-content {
      flex-direction: column;
      align-items: center;
      gap: 15px;
      width: 100%;
  }

  .image-gallery, .details-sidebar {
      width: 100%; /* Full-width on smaller screens */
  }

  .thumbnail-slider {
      justify-content: center;
      flex-wrap: wrap;
  }

  .thumbnail-slider img {
      width: 70px; /* Smaller thumbnails on mobile */
      margin: 5px; /* Add spacing between thumbnails */
  }

  .rent-button, .proceed-button, .out-of-stock-button {
      width: 100%;
      padding: 8px;
      font-size: 0.9em;
  }

  .price-list {
      gap: 4px;
  }

  .main-image img {
      max-height: 300px; /* Reduce the main image height for mobile screens */
  }
}
