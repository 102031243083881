.reviews-section {
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background-color: #6e5252;
    border-radius: 8px;
    border: 1px solid #ddd;
    max-height: 400px;
    overflow-y: auto;
}

.reviews-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid #ddd;
}

.add-review-button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}

.reviews-list {
    margin-top: 10px;
}

.review-item {
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}

.review-initial {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: #342b2b;
    border-radius: 50%;
    font-size: 1.2em;
    font-weight: bold;
    margin-right: 15px;
}

.review-content {
    flex: 1;
}

.review-date {
    font-size: 0.8em;
    color: #888;
}

.review-name {
    font-weight: bold;
}

.review-text {
    margin-top: 2px;
    margin-bottom: 2px;
}

.review-rating {
    color: #ffc107;
    font-size: 1.2em;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.back-button, .next-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

/* Styling for the review form */
.review-form {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    background-color: #4e3c3c;
    padding: 15px;
    border-radius: 8px;
}

.review-form input[type="text"],
.review-form input[type="date"],
.review-form textarea,
.review-form select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9em;
}

.review-form textarea {
    resize: vertical;
    height: 80px;
}

.review-form select {
    font-size: 0.9em;
}

.review-form button {
    background-color: #28a745;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.review-form button:hover {
    background-color: #218838;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
    .reviews-section {
        padding: 15px;
        max-height: 300px;
    }

    .reviews-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .add-review-button {
        font-size: 0.8em;
        padding: 5px 8px;
        margin-top: 10px;
    }

    .review-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .review-initial {
        width: 40px;
        height: 40px;
        font-size: 1em;
        margin-right: 0;
        margin-bottom: 8px;
    }

    .review-date, .review-name, .review-rating {
        font-size: 0.8em;
    }

    .navigation-buttons {
        flex-direction: column;
        gap: 10px;
    }

    .back-button, .next-button {
        padding: 8px 15px;
        font-size: 0.9em;
    }

    .review-form {
        padding: 10px;
    }

    .review-form input[type="text"],
    .review-form input[type="date"],
    .review-form textarea,
    .review-form select {
        font-size: 0.85em;
    }

    .review-form button {
        padding: 8px;
        font-size: 0.9em;
    }
}
