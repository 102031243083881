.all {
  background-color: rgb(18, 2, 2);
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns */
  gap: 20px; /* Space between items */
  padding: 20px;
}

.product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  background-color: #ddd;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin-bottom: 10px;
}

.add-to-cart-button {
  background-color: #4CAF50;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.add-to-cart-button:hover {
  background-color: #0ef319;
}

.proceed-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.proceed-button:hover {
  background-color: #0ef319;
}

.ctp {
  position: relative;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: #e0d9d9;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  border-top: 2px solid #ddd;
}

.clear-cart-button {
  margin-top: 10px;
  background-color: #ff4d4d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.clear-cart-button:hover {
  background-color: #ff3333;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: 1fr 1fr; /* 2 columns on smaller screens */
    gap: 15px; /* Reduced gap for mobile */
    padding: 10px; /* Less padding for mobile */
  }

  .product-card {
    padding: 8px; /* Reduced padding in product cards */
  }

  .product-image {
    max-width: 100px; /* Smaller images on mobile */
    margin-bottom: 8px;
  }

  .add-to-cart-button,
  .proceed-button,
  .clear-cart-button {
    padding: 8px 10px;
    font-size: 0.9em; /* Reduced font size on buttons */
    width: 100%; /* Full-width buttons for easier tapping */
  }

  .ctp {
    padding: 15px; /* Less padding in the footer */
    max-width: 100%; /* Full width on smaller screens */
  }
}
