.header {
  background-image: url('../Assets/header2.webp'); /* Path to the image */
  background-size: cover;
  background-position: center;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: white;
}

.header-overlay {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  padding: 2rem;
}

h1 {
  font-size: 3rem;
  margin: 0;
}

h3 {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.item {
  /* Optional, adjust width as needed */
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
  .header {
    height: 30vh; /* Increase height for smaller screens */
  }

  .header-overlay {
    padding: 1.5rem; /* Reduce padding */
  }

  h1 {
    font-size: 2rem; /* Smaller font size for mobile */
  }

  h3 {
    font-size: 1.2rem; /* Smaller font size for mobile */
  }
}

@media (max-width: 480px) {
  .header {
    height: 40vh; /* Increase height for extra-small screens */
  }

  .header-overlay {
    padding: 1rem; /* Further reduce padding */
  }

  h1 {
    font-size: 1.8rem; /* Smaller font size for extra-small screens */
  }

  h3 {
    font-size: 1rem; /* Smaller font size for extra-small screens */
  }
}
