.age-verification-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .age-verification-modal {
    background-color: #000;
    color: #fff;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
  }
  
  .age-verification-modal h1 {
    font-size: 1.8em;
    color: #fff;
    margin-bottom: 20px;
  }
  
  .age-verification-modal p {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .age-verification-modal ul {
    list-style-type: none;
    padding: 0;
    margin: 15px 0;
    font-size: 1em;
  }
  
  .age-verification-modal ul li {
    margin-bottom: 10px;
  }
  
  .age-verification-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .verify-button {
    background-color: #28a745;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .underage-button {
    background-color: #dc3545;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .verify-button:hover,
  .underage-button:hover {
    opacity: 0.9;
  }
  