body {
    background-color: black;
    margin: 0;
    padding: 0;
}

.homepage-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.homepage-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* 2 columns */
    grid-template-rows: 1fr 1fr; /* 2 rows */
    flex: 1;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.homepage-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s;
    padding: 20px;
    color: darkred;
    font-size: 2em;
}

.homepage-column:hover {
    transform: scale(1.05);
}

.homepage-image {
    width: 80%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 10px;
}

h3 {
    font-size: 1.5em;
    color: darkred;
    margin: 0;
}

.homepage-footer {
    width: 100%;
    padding: 15px 10px;
    background-color: #111;
    color: #aaa;
    text-align: center;
    font-size: 12px;
    line-height: 1.5;
    border-top: 1px solid #333;
    box-sizing: border-box;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
    .homepage-container {
        grid-template-columns: 1fr; /* 1 column for mobile */
        grid-template-rows: repeat(4, 1fr); /* Adjusts to 4 rows */
        padding: 10px; /* Reduce padding on mobile */
    }

    .homepage-column {
        font-size: 1.5em; /* Smaller font for mobile */
        padding: 15px; /* Reduce padding on mobile */
    }

    .homepage-image {
        width: 100%; /* Full width on mobile for better fit */
    }

    h3 {
        font-size: 1.2em; /* Adjust heading size */
    }

    .homepage-footer {
        font-size: 10px; /* Smaller footer text on mobile */
        padding: 10px; /* Reduce padding for mobile */
    }
}
