/* General styling for the button on the homepage */
.homepage button {
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #8b0000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.homepage button:hover {
    background-color: #a60000;
}

/* Main Component background styling */
.main-component {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

/* Modal overlay */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.modal-content {
    background-color: #ffffff;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    border-radius: 8px;
    text-align: center;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-content h3 {
    font-size: 24px;
    color: #8b0000;
    margin: 0;
}

.modal-content input[type="email"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content button {
    padding: 10px 20px;
    background-color: #8b0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.modal-content button:hover {
    background-color: #a60000;
}

/* Close Button */
.close-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #8b0000;
}

.close-button:hover {
    color: #a60000;
}

/* Form styling */
.form-page {
    background: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 600px;
    margin: auto;
    text-align: left;
    color: white;
}

.form-page h2 {
    font-size: 24px;
    color: #8b0000;
    text-align: center;
}

.form-page p {
    text-align: center;
    color: #eee;
    margin-bottom: 20px;
}

.form-page label {
    font-size: 14px;
    color: #ddd;
}

.form-page input[type="text"],
.form-page input[type="email"],
.form-page input[type="number"],
.form-page textarea,
.form-page select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #333;
    color: #fff;
}

.form-page textarea {
    height: 80px;
}

.form-page .side-image {
    position: absolute;
    top: 250px;
    right: 20px;
    width: 120px;
    border-radius: 8px;
}

.form-page .tribute-checkbox {
    margin-top: 15px;
}

.form-page .tribute-amount,
.form-page .tribute-frequency {
    display: inline-block;
    width: 48%;
}

.form-page button[type="submit"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #8b0000;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.form-page button[type="submit"]:hover {
    background-color: #a60000;
}

/* Mobile Responsive Styling */
@media (max-width: 768px) {
    .homepage button {
        padding: 10px 20px;
        font-size: 14px;
    }

    .modal-content {
        padding: 15px;
        width: 90%;
        max-width: 300px;
    }

    .modal-content h3 {
        font-size: 20px;
    }

    .modal-content input[type="email"] {
        font-size: 14px;
        padding: 8px;
    }

    .modal-content button {
        padding: 8px 16px;
        font-size: 14px;
    }

    .close-button {
        font-size: 20px;
    }

    .form-page {
        padding: 15px;
    }

    .form-page h2 {
        font-size: 20px;
    }

    .form-page label {
        font-size: 13px;
    }

    .form-page input[type="text"],
    .form-page input[type="email"],
    .form-page input[type="number"],
    .form-page textarea,
    .form-page select {
        font-size: 13px;
        padding: 6px;
    }

    .form-page .side-image {
        position: relative;
        top: auto;
        right: auto;
        width: 80px;
        margin: 20px auto;
        display: block;
    }

    .form-page .tribute-amount,
    .form-page .tribute-frequency {
        width: 100%; /* Full-width on mobile */
    }

    .form-page button[type="submit"] {
        padding: 8px;
        font-size: 14px;
    }
}
